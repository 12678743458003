import gql from 'graphql-tag'

const configResponse = `
  id paperType type
  name deviceId device {id type code name}
`

export const LIST_PAPER_CONFIG_TRELLO = (templateType) => gql`query LIST_PAPER_CONFIG_TRELLO ($paperType: String!, $q: FilterInput) {
  items: list${templateType}PaperConfigTrello (paperType: $paperType, q: $q) {${configResponse}}
}`

export const CREATE_PAPER_CONFIG_TRELLO = (templateType) => gql`mutation CREATE_PAPER_CONFIG_TRELLO ($paperType: String!, $input: PaperConfigTrelloInput!) {
  createItem: create${templateType}PaperConfigTrello (paperType: $paperType, input: $input) {${configResponse}}
}`

export const UPDATE_PAPER_CONFIG_TRELLO = (templateType) => gql`mutation UPDATE_PAPER_CONFIG_TRELLO ($paperType: String!, $paperConfigId: Int!, $input: PaperConfigTrelloInput!) {
  updateItem: update${templateType}PaperConfigTrello (paperType: $paperType, paperConfigId: $paperConfigId, input: $input) {${configResponse}}
}`

export const DESTROY_PAPER_CONFIG_TRELLO = (templateType) => gql`mutation DESTROY_PAPER_CONFIG_TRELLO ($paperType: String!, $paperConfigId: Int!) {
  destroyItem: destroy${templateType}PaperConfigTrello (paperType: $paperType, paperConfigId: $paperConfigId) {id}
}`
