<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          {{title}} - <span class="blink">ตั้งค่า</span>
          <router-link
            class="text-decoration-none text-secondary px-2"
            :to="toList()">
            <small><fa icon="list"></fa></small>
          </router-link>
        </h3>
      </div>

      <div class="dashhead-toolbar">
        <div class="dashhead-toolbar-item">
          <sgv-input-select
            :options="options"
            select="value"
            v-model="selected">
            <template slot-scope="option">
              {{option.text}}
            </template>
          </sgv-input-select>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <component
      :is="selected"
      :templateType="templateType"
      :paperType="paperType">
    </component>

  </div>
</template>

<script>
import PaperConfigTrello from '@/views/paper_template/components/PaperConfigTrello.vue'

export default {
  components: {
    PaperConfigTrello
  },
  metaInfo () {
    return {
      title: `${this.title}-ตั้งค่า`
    }
  },
  props: {
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      listView: `Paper${this.$form.capitalize(this.paperType)}List`,
      options: [
        {text: 'Trello', value: 'PaperConfigTrello'}
      ],
      selected: 'PaperConfigTrello'
    }
  },
  methods: {
    toList () {
      return {name: this.listView}
    },
  }
}
</script>

<style lang="css">
</style>
