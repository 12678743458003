<template>
  <div class="card">
    <div class="card-body" v-if="!toggle">
      <span class="card-text">
        <span
          class="mx-1 pointer float-right"
          @click="toggle = !toggle">
          <fa icon="pencil-alt" class="text-warning"></fa>
        </span>

        <div class="">
          List ID: {{paperConfig.name}}
        </div>
        <div class="">
          อุปกรณ์: {{paperConfig.device.code}} ({{paperConfig.device.name}})
        </div>
      </span>
    </div>

    <div class="card-body" v-if="toggle">
      <div class="card-text">
        <div class="form-row">
          <sgv-input-text
            class="col-12"
            label="List ID"
            v-model="formData.name"
            :validations="[
              {text: 'required!', value: $v.formData.name.$dirty && !$v.formData.name.required}
            ]">
          </sgv-input-text>

          <PaperConfigDropdownDevice
            paperConfigType="Trello"
            :paperType="paperType"
            :templateType="templateType"
            class="col-12"
            select="id"
            v-model="formData.deviceId"
            :validations="[
              {text: 'required!', value: $v.formData.deviceId.$dirty && !$v.formData.deviceId.required}
            ]">
          </PaperConfigDropdownDevice>
        </div>

        <button
          v-if="paperConfigId === -1"
          type="button"
          class="btn btn-success"
          @click="createData">
          เพิ่ม
        </button>

        <button
          v-if="paperConfigId > 0"
          type="button"
          class="btn btn-warning"
          @click="updateData">
          แก้ไข
        </button>

        <button
          v-if="toggle"
          type="button"
          class="btn btn-primary ml-2"
          @click="cancelData">
          ยกเลิก
        </button>

        <button
          v-if="paperConfigId > 0"
          type="button"
          class="btn btn-danger ml-2 float-right"
          @click="destroyData(paperConfigId)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import PaperConfigDropdownDevice from './PaperConfigDropdownDevice.vue'
import {
  CREATE_PAPER_CONFIG_TRELLO,
  UPDATE_PAPER_CONFIG_TRELLO,
  DESTROY_PAPER_CONFIG_TRELLO
} from '../graph/paper_config_trello'

export default {
  components: {
    PaperConfigDropdownDevice
  },
  props: {
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    paperConfig: {
      type: Object,
      required: false
    },
    paperConfigId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      toggle: false,
      formData: {
        name: '',
        deviceId: null
      }
    }
  },
  validations: {
    formData: {
      name: { required },
      deviceId: { required }
    }
  },
  methods: {
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      return  {
        name: v.name,
        deviceId: v.deviceId
      }
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_PAPER_CONFIG_TRELLO(this.templateType),
        variables: {
          paperType: this.paperType,
          input
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.cancelData()
        this.emitUpdated()
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_PAPER_CONFIG_TRELLO(this.templateType),
        variables: {
          paperType: this.paperType,
          paperConfigId: this.paperConfigId,
          input
        }
      })
      .then(res => {
        this.setFormData(res.data.updateItem)
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.emitUpdated()
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_PAPER_CONFIG_TRELLO(this.templateType),
        variables: {
          paperType: this.paperType,
          paperConfigId: this.paperConfigId,
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.emitUpdated()
      })
      .catch(this.$toasted.global.error)
    },
    cancelData () {
      this.toggle = false
      this.$emit('cancel', null)
    },
    emitUpdated () {
      this.toggle = false
      this.$emit('updated', null)
    }
  },
  watch: {
    toggle (value) {
      if (value && this.paperConfig) {
        this.formData.name = this.paperConfig.name
        this.formData.deviceId = this.paperConfig.deviceId
      }
    }
  },
  created () {
    if (this.paperConfigId === -1) {
      this.toggle = true
    }
  }
}
</script>

<style lang="css" scoped>
</style>
