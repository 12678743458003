import gql from 'graphql-tag'


export const DP_DEVICE = (templateType, paperConfigType) => gql`query DP_DEVICE ($paperType: String!, $q: FilterInput) {
  items: list${templateType}PaperConfig${paperConfigType}DropdownDevice (paperType: $paperType, q: $q) {
    id type code name
  }
}`

export const DP_DEVICE_TYPE = (templateType, paperConfigType) => gql`query DP_DEVICE_TYPE ($paperType: String!) {
  types: list${templateType}PaperConfig${paperConfigType}DropdownDeviceType (paperType: $paperType) {
    text value
  }
}`
