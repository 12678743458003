<template>
  <div class="">
    <div class="form-row">
      <div class="col-auto">
        <button
          v-if="!showCreateForm"
          type="button"
          class="btn btn-success mb-3 mr-2"
          @click="showCreateForm = true">
          เพิ่ม
        </button>
        <button
          type="button"
          class="btn btn-primary mb-3"
          @click="toList">
          กลับ
        </button>
      </div>

      <div class="col"></div>

      <sgv-input-text
        class="col-sm-auto col-12"
        v-model="search"
        placeholder="ค้นหา...">
      </sgv-input-text>
    </div>

    <PaperConfigTrelloCard
      key="createForm"
      class="mb-3"
      v-if="showCreateForm"
      :paperConfigId="-1"
      :paperType="paperType"
      :templateType="templateType"
      @cancel="showCreateForm = false"
      @updated="refetch">
    </PaperConfigTrelloCard>

    <PaperConfigTrelloCard
      class="mb-3"
      v-for="item in items"
      :key="item.id"
      :paperConfigId="item.id"
      :paperConfig="item"
      :paperType="paperType"
      :templateType="templateType"
      @updated="refetch">
    </PaperConfigTrelloCard>
  </div>
</template>

<script>
import PaperConfigTrelloCard from './PaperConfigTrelloCard.vue'
import { LIST_PAPER_CONFIG_TRELLO } from '../graph/paper_config_trello'

export default {
  components: {
    PaperConfigTrelloCard
  },
  props: {
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      listView : `Paper${this.$form.capitalize(this.paperType)}List`,
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      items: [],
      showCreateForm: false,
      search: ''
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_PAPER_CONFIG_TRELLO(this.templateType)
      },
      variables() {
        return {
          paperType: this.paperType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    getFilter (v) {
      return {
        ...v,
        params: {
          search: this.search,
          ...v.params
        }
      }
    },
    refetch () {
      this.$apollo.queries.items.refetch()
    },
    toList () {
      this.$router.push({name: this.listView})
    }
  },
}
</script>

<style lang="css" scoped>
</style>
